import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { notification, Spin } from 'antd';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  getTrooperProfile,
  acceptTrooperProfile,
} from '../../../state/actions/troopers';
import Layout from '../../../view/Admin/Layout';
import Guarantors from './Guarantors';
import { ProfileStyle } from '../../styles/Profile';
import { Loaders } from '../../styles/Card';

const Profile = ({
  getTrooperProfile,
  acceptTrooperProfile,
  loading,
  loader,
  trooper,
}) => {
  let { id } = useParams();

  const {
    firstName,
    surname,
    email,
    phone,
    address,
    dateOfBirth,
    imageUrl,
    guarantors,
    isTrooper,
  } = trooper;

  useEffect(() => {
    getTrooperProfile(id);

    if (isTrooper) setDisable(true);
  }, [getTrooperProfile, id, isTrooper]);

  const [disable, setDisable] = useState(false);

  const acceptTrooper = () => {
    acceptTrooperProfile(id).then((res) => {
      if (res.status === 201) {
        notification.open({
          message: 'Sucesss',
          description: res.data.message,
          icon: <CheckCircleOutlined style={{ color: '#4BB543' }} />,
        });
        setDisable(true);
      }
    });
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Layout>
      <ProfileStyle>
        <div className="info">
          {loading ? (
            <Loaders>
              <Spin indicator={antIcon} />,
            </Loaders>
          ) : (
            <>
              <h1>Trouper's Profile</h1>
              <h3>Basic Information</h3>
              <div className="details">
                {trooper && (
                  <div className="text">
                    <h2>Full Name</h2>
                    <div>
                      {firstName} {surname}
                    </div>

                    <h2>Email Address</h2>
                    <div>{email}</div>

                    <h2>Phone Number</h2>
                    <div>{phone}</div>

                    <h2>Home Address</h2>
                    <div>{address}</div>

                    <h2>D.O.B</h2>
                    <div>{dateOfBirth}</div>
                  </div>
                )}
                <div className="img">
                  <img src={imageUrl} alt={firstName} />
                </div>
              </div>

              <div className="guarantors">
                <h3>Guarantor Information</h3>
                <div className="details">
                  {guarantors && (
                    <div>
                      {guarantors.map((guarantor, idx) => (
                        <Guarantors guarantor={guarantor} idx={idx} key={idx} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="btn">
                <button>
                  <Link to="/requests">Cancel</Link>
                </button>
                <button onClick={acceptTrooper} disabled={disable}>
                  {loader ? (
                    <Spin indicator={antIcon} />
                  ) : isTrooper ? (
                    'Accepted'
                  ) : (
                    'Accept Trouper'
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </ProfileStyle>
    </Layout>
  );
};

const mapStateToProps = ({ trooperReducer }) => ({
  loading: trooperReducer.loading,
  loader: trooperReducer.loader,
  trooper: trooperReducer.trooper,
  error: trooperReducer.error,
});

export default connect(mapStateToProps, {
  getTrooperProfile,
  acceptTrooperProfile,
})(Profile);
