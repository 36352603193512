import styled from 'styled-components';

export const FooterStyles = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
  background: #081c44;
  color: #fff;
  padding: 20px;
  .socials {
    display: flex;
    justify-content: space-around;
    .icon {
      font-size: 20px;
      width: 30px;
      color: #fff;
    }
  }
  .contact {
    svg {
      font-size: 18px;
      margin-right: 10px;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    height: 150px;
    div {
      font-weight: bolder;
      display: flex;
      justify-content: baseline;
    }
  }
  @media (min-width: 1023px) {
    div {
      justify-content: center;
      &:not(.socials) {
        width: 25%;
      }
      &:last-child {
        width: 35%;
      }
    }
  }
`;
