import styled from 'styled-components';

export const DashboardStyle = styled.div`
  display: flex;
  justify-content: space-between;
  .card {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 30%;
    height: 132px;
    background: #ffffff;
    border-radius: 10px;
    margin: 20px 0;
    svg {
      color: #fff;
      margin: 30%;
      font-size: 24px;
    }
    .icon {
      border-radius: 10px;
      width: 64px;
      height: 64px;
      &.red {
        background: #ed2f6b;
        box-shadow: 0px 8px 16px rgba(243, 8, 82, 0.2);
      }
      &.blue {
        background: #166cdd;
        box-shadow: 0px 8px 16px rgba(34, 34, 74, 0.2);
      }
      &.yellow {
        background: #ffbe0c;
        box-shadow: 0px 8px 16px rgba(242, 181, 13, 0.2);
      }
    }
    div {
      font-size: 20px;
      font-weight: bolder;
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        div {
          &:nth-child(2) {
            color: #166cdd;
          }
        }
      }
    }
  }
`;
