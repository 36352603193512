import React from 'react';
import { Link } from 'react-router-dom';
import { NavStyle } from './../styles/Nav';
import logo from '../../assets/ELA_logo.png';
import { Button } from 'antd';
import { clearLocalStorage } from '../../utils/localStorage';

const NavBar = () => {
  return (
    <NavStyle>
      <div>
        <Link to="/">
          <img src={logo} alt="ELA Logo" />
        </Link>
      </div>
      <div className="logout">
        <Button type="link" onClick={() => clearLocalStorage()}>
          Log Out
        </Button>
      </div>
    </NavStyle>
  );
};

export default NavBar;
