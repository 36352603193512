import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin, Table, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getAcceptedTrooperProfile } from '../../../state/actions/troopers';
import Layout from '../../../view/Admin/Layout';
import { ProfileStyle } from './../../styles/Profile';
import { Loaders } from './../../styles/Card';

const { Column } = Table;

const TrooperProfile = ({
  getAcceptedTrooperProfile,
  loading,
  acceptedTrooper,
}) => {
  let { id } = useParams();

  useEffect(() => {
    getAcceptedTrooperProfile(id);
  }, [getAcceptedTrooperProfile, id]);

  const { User, orders } = acceptedTrooper;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Layout>
      <ProfileStyle>
        <div className="info">
          {loading ? (
            <Loaders>
              <Spin indicator={antIcon} />,
            </Loaders>
          ) : (
            <>
              <h1>Trouper's Profile</h1>
              <h3>Basic Information</h3>
              <div className="details">
                {User && (
                  <div className="text">
                    <h2>First Name</h2>
                    <div>{User.firstName}</div>
                    <h2>Surname</h2>
                    <div>{User.surname}</div>
                    <h2>Email Address</h2>
                    <div>{User.email}</div>

                    <h2>Phone Number</h2>
                    <div>{User.phone}</div>

                    <h2>Home Address</h2>
                    <div>{User.address}</div>

                    <h2>D.O.B</h2>
                    <div>{User.dateOfBirth}</div>
                  </div>
                )}
                <div className="img">
                  {User && <img src={User.imageUrl} alt={User.firstName} />}
                </div>
              </div>

              <div className="orders">
                <h3>Orders</h3>

                <Table
                  dataSource={orders}
                  rowKey="id"
                  loading={{ spinning: loading, indicator: antIcon }}
                >
                  <Column title="ID" dataIndex="id" />
                  <Column
                    title="Status"
                    dataIndex="status"
                    render={(status) =>
                      status === 3 ? (
                        <Tag color="pink">Awaiting Pickup</Tag>
                      ) : status === 5 ? (
                        <Tag color="blue">Picked Up</Tag>
                      ) : status === 7 ? (
                        <Tag color="green">Delivered</Tag>
                      ) : status === 4 ? (
                        <Tag color="yellow">In Transit</Tag>
                      ) : (
                        ''
                      )
                    }
                  />
                  <Column
                    title="Sender"
                    render={(item, record) => (
                      <>
                        <div>
                          {item.pickupFirstName} {item.pickupSurname}
                        </div>
                      </>
                    )}
                  />
                  <Column
                    title="Reciever"
                    render={(item, record) => (
                      <>
                        <div>
                          {item.deliveryFirstName} {item.deliverySurname}
                        </div>
                      </>
                    )}
                  />
                </Table>
              </div>
            </>
          )}
        </div>
      </ProfileStyle>
    </Layout>
  );
};

const mapStateToProps = ({ trooperReducer }) => ({
  loading: trooperReducer.loading,
  loader: trooperReducer.loader,
  acceptedTrooper: trooperReducer.acceptedTrooper,
  error: trooperReducer.error,
});

export default connect(mapStateToProps, {
  getAcceptedTrooperProfile,
})(TrooperProfile);
