import React from 'react';
import {
  AiFillTwitterCircle,
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiOutlineMail,
} from 'react-icons/ai';

import { FaSnapchat, FaPhoneAlt } from 'react-icons/fa';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { FooterStyles } from './../styles/Footer';

const socials = [
  { icon: AiFillTwitterCircle, name: 'Twitter' },
  { icon: AiOutlineInstagram, name: 'Instagram' },
  { icon: FaSnapchat, name: 'SnapChat' },
  { icon: RiFacebookCircleLine, name: 'Facebook' },
  { icon: AiOutlineYoutube, name: 'YouTube' },
];

const Footer = () => {
  return (
    <FooterStyles>
      <div className="contact">
        <FaPhoneAlt /> <span>0817 000 0352</span>
      </div>
      <div className="contact">
        <AiOutlineMail /> <span>hello@ela-delivers.com</span>
      </div>
      <div className="socials">
        {socials.map((item, idx) => (
          <div className="icon" key={idx}>
            <item.icon />
          </div>
        ))}
      </div>
      <div>Terms & Conditions</div>
      <div>
        &copy;Copyright {new Date().getFullYear()} ELA. All Right Reserved.
      </div>
    </FooterStyles>
  );
};

export default Footer;
