import axios from 'axios';
import { apiURL } from '../../config';
import { setToken } from '../../utils/localStorage';
import { axiosWithAuth } from '../../utils/axiosWithAuth';

export const ADMIN_AUTH_LOADING = 'ADMIN_AUTH_LOADING';
export const ADMIN_AUTH_SUCCESS = 'ADMIN_AUTH_SUCCESS';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESET_PWD_CODE_SUCCESS = 'RESET_PWD_CODE_SUCCESS';
export const ADMIN_AUTH_FAILURE = 'ADMIN_AUTH_FAILURE';

export const createAdmin = (admin) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_AUTH_LOADING });
    const res = await axios.post(`${apiURL}/admin/auth/register`, admin);
    setToken(res.data.data.token);
    dispatch({ type: ADMIN_AUTH_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({
      type: ADMIN_AUTH_FAILURE,
      payload: error.response?.data.message,
    });
    return error.response?.data;
  }
};

export const adminLogin = (admin) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_AUTH_LOADING });
    const res = await axios.post(`${apiURL}/admin/auth/login`, admin);
    setToken(res.data.data.token);
    dispatch({ type: ADMIN_AUTH_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({
      type: ADMIN_AUTH_FAILURE,
      payload: error.response?.data.message,
    });
    return error.response?.data;
  }
};

export const verifyEmailCode = (code) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_AUTH_LOADING });
    const res = await axiosWithAuth().post(
      `${apiURL}/admin/auth/verify-email-code`,
      code
    );
    dispatch({ type: VERIFY_CODE_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({
      type: ADMIN_AUTH_FAILURE,
      payload: error.response?.data.message,
    });
    return error.response?.data;
  }
};

export const resendEmailCode = (type = '') => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_AUTH_LOADING });
    const res = await axiosWithAuth().post(
      `${apiURL}/admin/auth/resend-email-code`,
      type
    );
    dispatch({ type: RESEND_CODE_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({
      type: ADMIN_AUTH_FAILURE,
      payload: error.response?.data.message,
    });
    return error.response?.data;
  }
};

export const generateForgotPasswordCode = (email) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_AUTH_LOADING });
    const res = await axiosWithAuth().post(
      `${apiURL}/admin/auth/password-reset-email-verification`,
      email
    );
    setToken(res.data.data.token);
    dispatch({ type: RESET_PWD_CODE_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({
      type: ADMIN_AUTH_FAILURE,
      payload: error.response?.data.message,
    });
    return error.response?.data;
  }
};

export const verifyEmailPasswordCode = (code) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_AUTH_LOADING });
    const res = await axiosWithAuth().post(
      `${apiURL}/admin/auth/password-reset-code-verification`,
      code
    );
    dispatch({ type: VERIFY_CODE_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({
      type: ADMIN_AUTH_FAILURE,
      payload: error.response?.data.message,
    });
    return error.response?.data;
  }
};

export const resetPassword = (password) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_AUTH_LOADING });
    const res = await axiosWithAuth().post(
      `${apiURL}/admin/auth/password-reset`,
      password
    );
    dispatch({ type: VERIFY_CODE_SUCCESS, payload: res.data });
    return res;
  } catch (error) {
    dispatch({
      type: ADMIN_AUTH_FAILURE,
      payload: error.response?.data.message,
    });
    return error.response?.data;
  }
};
