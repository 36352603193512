import React from 'react';
import { Link as SLink } from 'react-scroll';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/ELA_logo.JPG';
import { HomeNavStyles } from '../styles/Nav';

const HomeNav = () => {
  const menu = ['Home', 'About Us', 'Services', 'Why ELA', 'How It Works'];
  let { pathname } = useLocation();
  return (
    <HomeNavStyles>
      <div className="nav-logo">
        <Link to="/">
          <img src={logo} alt="ELA logo" />
        </Link>
      </div>
      {pathname === '/' ? (
        <ul className="nav-items">
          {menu.map((item, idx) => {
            return item === 'How It Works' ? (
              <li key={idx}>
                <Link to="how">{item}</Link>
              </li>
            ) : item === 'Home' ? (
              <li key={idx}>
                <Link to="/">{item}</Link>
              </li>
            ) : (
              <li key={idx}>
                <SLink
                  activeClass="active"
                  to={item}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  {item}
                </SLink>
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className="nav-items" style={{ width: 'auto' }}>
          {menu.map((item, idx) => {
            return item === 'How It Works' ? (
              <li key={idx}>
                <Link to="how">{item}</Link>
              </li>
            ) : item === 'Home' ? (
              <li key={idx}>
                <Link to="/">{item}</Link>
              </li>
            ) : (
              ''
            );
          })}
        </ul>
      )}
    </HomeNavStyles>
  );
};

export default HomeNav;
