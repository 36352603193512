import {
  TROOPERS_LOADING,
  ACCEPT_TROOPER_LOADING,
  GET_TROOPERS_SUCCESS,
  GET_TROOPER_SUCCESS,
  GET_ACCEPTED_TROOPERS_SUCCESS,
  GET_ACCEPTED_TROOPER_SUCCESS,
  ACCEPT_TROOPER_SUCCESS,
  REJECT_TROOPER_SUCCESS,
  UPDATE_TROOPER_SUCCESS,
  GET_TROOPERS_FAILURE,
  RESET_TROOPERS,
} from '../actions/troopers';

export const initialState = {
  loading: false,
  loader: false,
  troopers: {},
  trooper: {},
  acceptedTroopers: {},
  acceptedTrooper: {},
  accepted: '',
  success: '',
  rejected: '',
  error: '',
};

const trooperReducer = (state = initialState, action) => {
  switch (action.type) {
    case TROOPERS_LOADING:
      return { ...state, loading: true, loader: true };
    case ACCEPT_TROOPER_LOADING:
      return { ...state, loader: true };
    case GET_TROOPERS_SUCCESS:
      return {
        ...state,
        loading: false,
        troopers: action.payload,
      };
    case GET_TROOPER_SUCCESS:
      return {
        ...state,
        loading: false,
        loader: false,
        trooper: action.payload,
      };

    case GET_ACCEPTED_TROOPERS_SUCCESS:
      return {
        ...state,
        loading: false,
        acceptedTroopers: action.payload,
      };
    case GET_ACCEPTED_TROOPER_SUCCESS:
      return {
        ...state,
        loading: false,
        acceptedTrooper: action.payload,
      };
    case ACCEPT_TROOPER_SUCCESS:
      return {
        ...state,
        loading: false,
        loader: false,
        accepted: action.payload,
      };

    case UPDATE_TROOPER_SUCCESS:
      return {
        ...state,
        loading: false,
        loader: false,
        success: action.payload,
        acceptedTroopers: {
          ...state.acceptedTroopers,
          troopers: state.acceptedTroopers.troopers.map((t) =>
            t.id === action.payload.id ? action.payload : t
          ),
        },
      };

    case REJECT_TROOPER_SUCCESS:
      return {
        ...state,
        loading: false,
        rejected: action.payload,
        troopers: {
          ...state.troopers,
          troopers: state.troopers.troopers.filter(
            (t) => t.id !== Number(action.payload.data)
          ),
        },
      };
    case GET_TROOPERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case RESET_TROOPERS:
      return initialState;

    default:
      return state;
  }
};

export default trooperReducer;
