import { isTokenExpired } from './checkToken';
import jwt from 'jsonwebtoken';
import { word } from '../config';
export const setToken = (payload) => {
  try {
    const token = JSON.stringify(payload);
    localStorage.setItem('token', token);
  } catch {
    return undefined;
  }
};

export const getToken = () => {
  try {
    const token = JSON.parse(localStorage.getItem('token'))
    if (!token) {
      return undefined;
    }
    const isExpired = isTokenExpired(token); // Check if token is expired
    if (isExpired) {
      clearLocalStorage();
      return undefined;
    }
    let allow = jwt.verify(token, word, (err) => {
      if (err) {
        clearLocalStorage();
        return undefined;
      } else {
        return token;;
      }
    });
    return allow ? token : undefined;
  } catch (err) {
    return undefined;
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem('token');
  window.location.href = '/login';
};
