import { combineReducers } from 'redux';
import trooperReducer from './troopers';
import adminReducer from './admin';
import userReducer from './users';
import orderReducer from './orders';

const rootReducer = combineReducers({
  userReducer,
  trooperReducer,
  adminReducer,
  orderReducer,
});

export default rootReducer;
