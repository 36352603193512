import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, notification, Modal } from 'antd';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { FormStyle, PasswordStyle } from '../styles/Form';
import { resetPassword } from '../../state/actions/admin';
import lock from '../../assets/lockSucess.svg';

const { Item } = Form;

const PasswordReset = ({ resetPassword, loading }) => {
  const [successful, setSuccess] = useState(false);

  const onSubmit = async (values) => {
    try {
      const admin = await resetPassword(values);
      if (admin.status !== 200) {
        notification['error']({
          message: 'Error',
          description: admin.message,
          duration: 5,
        });
      } else {
        notification['success']({
          message: 'Success',
          description: admin.data.message,
          duration: 5,
        });
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <FormStyle>
        <PasswordStyle>
          <div className="logo">
            <h2>Reset Password</h2>
            <p>Enter a new password to reset your password</p>
          </div>
          <Form
            layout="vertical"
            className="register"
            initialValues={{ password: '' }}
            onFinish={onSubmit}
            scrollToFirstError
          >
            <Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password.',
                },
                { min: 8, message: 'Passsword must be minimum 8 characters.' },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Item>
            <Item
              label="Confirm Password"
              name="confirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password.',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject('The passwords do not match.');
                  },
                }),
              ]}
            >
              <Input.Password />
            </Item>
            <Item>
              <Button htmlType="submit" block loading={loading}>
                Reset Password
              </Button>
            </Item>
          </Form>
        </PasswordStyle>
        <Modal
          centered
          visible={successful}
          onOk={() => setSuccess(false)}
          onCancel={() => setSuccess(false)}
          closable={false}
          maskClosable={false}
          width={600}
          footer={null}
          bodyStyle={{ paddingTop: 50, paddingBottom: 50 }}
          maskStyle={{ backgroundColor: 'rgba(9, 70, 150, 0.6)' }}
          wrapClassName="modal-body"
        >
          <div className="modal">
            <img src={lock} alt="Password change success lock" />
            <h2>Password Changed Sucessfully</h2>
            <Button block>
              <Link to="/login">Completed</Link>
            </Button>
          </div>
        </Modal>
      </FormStyle>
    </Layout>
  );
};

const mapStateToProps = ({ adminReducer }) => ({
  loading: adminReducer.loading,
  user: adminReducer.user,
  error: adminReducer.error,
});

export default connect(mapStateToProps, {
  resetPassword,
})(PasswordReset);
