import {
  ADMIN_AUTH_LOADING,
  ADMIN_AUTH_SUCCESS,
  VERIFY_CODE_SUCCESS,
  RESEND_CODE_SUCCESS,
  RESET_PWD_CODE_SUCCESS,
  ADMIN_AUTH_FAILURE,
} from '../actions/admin';

const initialState = {
  user: {},
  success: '',
  error: null,
  loading: false,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...action.payload,
        },
      };
    case VERIFY_CODE_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: false,
      };

    case RESEND_CODE_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: false,
      };

    case RESET_PWD_CODE_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: false,
      };
      
    case ADMIN_AUTH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default adminReducer;
