import {
  USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_COUNTS_SUCCESS,
  GET_USERS_FAILURE,
  GET_COUNTS_FAILURE,
} from '../actions/users';

export const initialState = {
  loading: false,
  users: {},
  counts: {},
  error: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_LOADING:
      return { ...state, loading: true };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case GET_COUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        counts: action.payload,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_COUNTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
