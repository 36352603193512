import React from 'react';
import { connect } from 'react-redux';
import { Table, Space, Avatar, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { rejectTrooper, updateTrooperStatus } from '../../../state/actions/troopers';

const { Column } = Table;

const Troopers = ({
  loading,
  acceptedTroopers,
  getAcceptedTroopers,
  loader,
  updateTrooperStatus,
}) => {
  const { troopers: data } = acceptedTroopers;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
      <Table
        dataSource={data}
        rowKey="id"
        pagination={{
          position: 'bottomLeft',
          defaultPageSize: 10,
          total: acceptedTroopers ? acceptedTroopers.totalItems : '',
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          onChange: (page, size) => {
            let currentNo = page - 1;
            getAcceptedTroopers(currentNo);
          },
        }}
        rowClassName="row"
        loading={{ spinning: loading, indicator: antIcon }}
        onChange={(page, size) => {
          let currentNo = page - 1;
          getAcceptedTroopers(currentNo);
        }}
      >
        <Column
          title="Full Name"
          render={(item, record) => (
            <Space size="large" align="baseline">
              <Avatar
                size="large"
                src={item.User.imageUrl}
                alt={item.User.firstName}
              />
              <h3>
                {item.User.firstName} {item.User.surname}
              </h3>
            </Space>
          )}
        />
        <Column
          title="Phone"
          render={(item, record) => <>{item.User.phone}</>}
        />
        <Column
          title="Email Address"
          render={(item, record) => <>{item.User.email}</>}
        />
        <Column
          title="Active"
          render={(item, record) => (
            <Switch
              checked={item.isActive}
              onChange={() => {
                updateTrooperStatus(item.id, { active: !item.isActive });
              }}
              loading={loader}
            />
          )}
        />
        <Column
          title="Action"
          key="action"
          render={(item, record) => (
            <div className="action">
              <Link to={`/troopers/${item.id}`}>
                <button>View Details</button>
              </Link>
            </div>
          )}
        />
      </Table>
    </>
  );
};

export default connect(null, { rejectTrooper, updateTrooperStatus })(Troopers);
