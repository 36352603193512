import styled from 'styled-components';

export const CardStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-size: 18px;
  width: 90%;
  margin: 10px auto;
  padding: 10px;
  border-radius: 12px;
  .name {
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      margin: 0 10px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  h3 {
    color: rgba(31, 31, 32, 0.6);
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 10px;
      font-size: 16px;
      background-color: #166cdd;
      border-radius: 12px;
      color: #fff;
      margin: 0 10px;
    }
  }
`;

export const Loaders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
`;
