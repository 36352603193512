import styled from 'styled-components';

export const HowStyles = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  h2 {
    font-size: 28px;
    font-weight: bolder;
    margin: 10px 0;
  }
  .underline {
    width: 117px;
    border-bottom: 5px solid #ffbe0c;
    margin: 0 auto;
  }

  .screens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 10px;
    &.reverse {
      flex-direction: column-reverse;
    }
    img {
      margin-bottom: 10px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        font-weight: bolder;
        text-transform: capitalize;
        font-size: 24px;
        line-height: 2;
        text-align: center;
      }

      p {
        line-height: 2;
        text-transform: capitalize;
        text-align: center;
      }
      span {
        font-weight: bolder;
      }
    }
  }

  @media (min-width: 768px) {
    .underline {
      margin-bottom: 20px;
    }
    .screens {
      width: 60%;
      flex-direction: row;
      justify-content: space-between;
      &.reverse {
        flex-direction: row;
        justify-content: space-between;
      }
      .img {
        width: 300px;
      }
      div {
        justify-content: flex-start;
        width: 400px;
        align-items: flex-start;
        margin: 0 20px;
        h3 {
          text-align: left;
        }
        p {
          text-align: left;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .screens {
      width: 85%;
    }
  }
`;
