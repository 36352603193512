import styled from 'styled-components';

export const SideNavStyle = styled.div`
  .ant-menu {
    .ant-menu-item-selected {
      background-color: rgba(29, 106, 255, 0.1);
      &:after {
        border-right-color: rgba(255, 255, 255, 0.1);
      }
    }
    .ant-menu-item {
      color: #9fa9bc;
      font-size: 16px;
      margin-bottom: 0;
      a {
        color: #9fa9bc;
        span {
          margin: 0 10px 0 30px;
        }
        &:hover,
        &.selected {
          color: #1d6aff;
        }
      }

      &:hover,
      &.selected {
        color: #1d6aff;
        background-color: rgba(29, 106, 255, 0.1);
        &:not(.sub) {
          border-left: 5px solid #1d6aff;
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        font-size: 16px;
        color: #9fa9bc;
        span {
          &:nth-child(2) {
            color: #9fa9bc;
            margin: 0 10px 0 30px;
          }
        }
      }
      &:hover,
      &.selected {
        color: #1d6aff;
        background-color: rgba(29, 106, 255, 0.1);
        border-left: 5px solid #1d6aff;
      }
    }
  }
`;

export const NavStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px 10px 20px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2);

  .logout {
    border-left: 1px solid #d6dce1;
    padding-left: 30px;
    color: #1d6aff;
  }
`;

export const HomeNavStyles = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 8px 16px rgba(236, 231, 231, 0.2);
  .nav-logo {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 100px;
    width: 150px;
    img {
      width: 100%;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 20px;
    }
  }
  .nav-items {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      list-style: none;
      margin: 10px 20px;
      width: 650px;
      li {
        width: 120px;
        padding: 10px;
        a {
          font-size: 14px;
          background-image: linear-gradient(#ffbe0c, #ffbe0c);
          background-position: 0% 100%;
          background-repeat: no-repeat;
          background-size: 0% 1px;
          transition: background-size cubic-bezier(0, 0.5, 0, 1) 0.5s;
          padding: 5px 0;
          &:hover,
          &:focus {
            background-size: 100% 3px;
            color: #000;
            font-weight: bolder;
          }
        }
      }
    }
  }
`;
