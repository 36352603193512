import React from 'react';
import Footer from '../../components/Landing/Footer';
import HomeNav from '../../components/Landing/HomeNav';
import login from '../../assets/login.png';
import order from '../../assets/order.png';
import details from '../../assets/details.png';
import checkout from '../../assets/checkout.png';
import { HowStyles } from './../styles/HowItWorks';

const HowItWorks = () => {
  return (
    <>
      <HomeNav />
      <HowStyles>
        <h2>How it works</h2>
        <div className="underline"></div>

        <div className="screens">
          <div className="img">
            <img src={login} alt="Login mobile page" />
          </div>
          <div>
            <h3>Login page</h3>
            <p>
              Input your email and password. Click <span>”Sign In”</span>{' '}
            </p>
          </div>
        </div>

        <div className="screens reverse">
          <div>
            <h3>Order Creation Page</h3>
            <p>
              You Can Define <span>Pickup and Delivery</span> details, Schedule
              An Order Now or Later.
            </p>
          </div>
          <div className="img">
            <img src={order} alt="Order mobile page" />
          </div>
        </div>

        <div className="screens">
          <div className="img">
            <img src={details} alt="Order details mobile page" />
          </div>
          <div>
            <h3>pickup and Delivery Details</h3>
            <p>
              Enter the Pickup and delivery address,full name and email address
              on each end then click on <span>“proceed”</span>
            </p>
          </div>
        </div>

        <div className="screens reverse">
          <div>
            <h3>select Payment method and check out</h3>
            <p>
              select the desired payment method: debit/credit card or cash.
              Click on <span>“Pay”</span> to complete your request.
            </p>
          </div>
          <div className="img">
            <img src={checkout} alt="Order mobile page" />
          </div>
        </div>
      </HowStyles>

      <Footer />
    </>
  );
};

export default HowItWorks;
