import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Layout from './Layout';
import { VerifyEmailStyle } from '../styles/Form';
import {
  verifyEmailPasswordCode,
  resendEmailCode,
} from '../../state/actions/admin';
import { Button, notification } from 'antd';

const PwdResetVerifyEmail = ({
  verifyEmailPasswordCode,
  resendEmailCode,
  loading,
}) => {
  let history = useHistory();

  const [otp, setOtp] = useState(0);
  const handleChange = (value) => {
    setOtp(value);
  };
  const onSubmit = async () => {
    try {
      const verification = await verifyEmailPasswordCode({ emailCode: otp });

      if (verification.status !== 200) {
        notification['error']({
          message: 'Error',
          description: verification.message,
          duration: 5,
        });
      } else {
        notification['success']({
          message: 'Success',
          description: verification.data.message,
          duration: 5,
        });

        history.push('/reset-password');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resend = async () => {
    try {
      const resendCode = await resendEmailCode({ type: 'passwordReset' });
      if (resendCode.status !== 200) {
        notification['error']({
          message: 'Error',
          description: resendCode.message
            ? resendCode.message
            : resendCode.data.message,
          duration: 5,
        });
      } else {
        notification['success']({
          message: 'Success',
          description: resendCode.data.message,
          duration: 5,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <VerifyEmailStyle>
        <h3>Verify Email</h3>
        <p>We sent you a code to verify your Email Address</p>

        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          isInputNum={true}
          containerStyle={'verify'}
          inputStyle={'inputStyle'}
        />

        <div className="bottom">
          <div>I didn’t get a code!</div>
          <Button type="link" onClick={resend}>
            Resend
          </Button>
        </div>
        <Button
          htmlType="submit"
          block
          disabled={!(('' + otp).split('').length === 4)}
          onClick={onSubmit}
          loading={loading}
        >
          Verify
        </Button>
      </VerifyEmailStyle>
    </Layout>
  );
};

const mapStateToProps = ({ adminReducer }) => ({
  loading: adminReducer.loading,
  success: adminReducer.success,
  error: adminReducer.error,
});

export default connect(mapStateToProps, {
  verifyEmailPasswordCode,
  resendEmailCode,
})(PwdResetVerifyEmail);
