import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../view/Admin/Dashboard';
import Profile from '../components/Admin/Troopers/Profile';
import Home from '../view/Landing/Home';
import HowItWorks from '../view/Landing/HowItWorks';
import Signup from '../components/Forms/Signup';
import Login from '../components/Forms/Login';
import ProtectedRoutes from '../utils/ProtectedRoutes';
import AcceptedTroopers from '../view/Admin/AcceptedTroopers';
import TrooperProfile from '../components/Admin/Troopers/TrooperProfile';
import VerifyEmail from '../components/Forms/VerifyEmail';
import FindEmail from '../components/Forms/FindEmail';
import PwdResetVerifyEmail from '../components/Forms/PwdResetVerifyEmail';
import PasswordReset from '../components/Forms/PasswordReset';
import Users from '../view/Admin/Users';
import PendingOrders from '../view/Admin/PendingOrders';
import DeliveredOrders from '../view/Admin/DeliveredOrders';
import OrderDetail from '../components/Admin/Orders/OrderDetail';
import TrooperRequests from '../view/Admin/TrooperRequests';
import Terms from '../view/Landing/Terms';

const appRoutes = () => (
  <Switch>
    <ProtectedRoutes exact path="/dashboard">
      <Dashboard />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/requests">
      <TrooperRequests />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/requests/trooper/:id">
      <Profile />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/troopers">
      <AcceptedTroopers />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/troopers/:id">
      <TrooperProfile />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/users">
      <Users />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/orders/pending">
      <PendingOrders />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/orders/delivered">
      <DeliveredOrders />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/order/:id">
      <OrderDetail />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/verify-email">
      <VerifyEmail />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/password-verify-email">
      <PwdResetVerifyEmail />
    </ProtectedRoutes>

    <ProtectedRoutes exact path="/reset-password">
      <PasswordReset />
    </ProtectedRoutes>

    <Route exact path="/" component={Home} />
    <Route exact path="/how" component={HowItWorks} />
    <Route exact path="/terms" component={Terms} />

    <Route exact path="/register" component={Signup} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/find-email" component={FindEmail} />
  </Switch>
);

export default appRoutes;
