import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import Layout from './Layout';
import { FormStyle } from '../styles/Form';
import logo from '../../assets/ELA_logo.JPG';
import { createAdmin } from '../../state/actions/admin';

const { Item } = Form;

const Signup = ({ createAdmin, loading }) => {
  let history = useHistory();
  const initialValues = {
    firstName: '',
    surname: '',
    email: '',
    password: '',
  };

  const onSubmit = async (values) => {
    try {
      const admin = await createAdmin(values);
      if (admin.status !== 201) {
        notification['error']({
          message: 'Error',
          description: admin.message,
          duration: 5,
        });
      } else {
        history.push('/verify-email');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <FormStyle>
        <div className="logo">
          <img src={logo} alt="ELA Logo" />
          <h2>Create an Account</h2>
        </div>
        <Form
          layout="vertical"
          className="register"
          initialValues={initialValues}
          onFinish={onSubmit}
          scrollToFirstError
        >
          <Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: 'Please input your first name.',
              },
              { min: 2, message: 'First name must be minimum 2 characters.' },
              {
                type: 'string',
                pattern: /^[a-zA-Z]+$/,
                message: 'First name must not contain numbers.',
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            label="Surname"
            name="surname"
            rules={[
              {
                required: true,
                message: 'Please input your surname.',
              },
              { min: 2, message: 'Surname must be minimum 2 characters.' },
              {
                type: 'string',
                pattern: /^[a-zA-Z]+$/,
                message: 'Surname must not contain numbers.',
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail.',
              },
              {
                required: true,
                message: 'Please input your E-mail.',
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password.',
              },
              { min: 8, message: 'Passsword must be minimum 8 characters.' },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Item>
          <Item
            label="Confirm Password"
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password.',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject('The passwords do not match.');
                },
              }),
            ]}
          >
            <Input.Password />
          </Item>
          <Item>
            <Button htmlType="submit" block loading={loading}>
              Next
            </Button>
            <div className="link">
              Already have an Account? <Link to="/login">Log In</Link>
            </div>
          </Item>
        </Form>
      </FormStyle>
    </Layout>
  );
};

const mapStateToProps = ({ adminReducer }) => ({
  loading: adminReducer.loading,
  user: adminReducer.user,
  error: adminReducer.error,
});

export default connect(mapStateToProps, {
  createAdmin,
})(Signup);
