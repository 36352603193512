import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as dayjs from 'dayjs';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FaUser, FaPhoneAlt, FaRegClock, FaRegCalendar } from 'react-icons/fa';
import { FiTruck, FiMail } from 'react-icons/fi';

import { getOrderDetails } from '../../../state/actions/orders';
import Layout from '../../../view/Admin/Layout';
import { ProfileStyle } from '../../styles/Profile';
import { Loaders } from '../../styles/Card';
import locator from '../../../assets/locate.svg';
import Map from './Map';

const OrderDetail = ({ getOrderDetails, loading, detail }) => {
  let { id } = useParams();

  const {
    pickupAddress,
    deliveryAddress,
    pickupCoordinates,
    deliveryCoordinates,
    pickupFirstName,
    pickupSurname,
    pickupPhone,
    deliveryFirstName,
    deliverySurname,
    deliveryPhone,
    schedule,
    deliveryType,
    priceText,
    isCashTransaction,
  } = detail;

  let pickup;
  let deliver;
  if (pickupCoordinates) {
    pickup = JSON.parse(pickupCoordinates);
    deliver = JSON.parse(deliveryCoordinates);
  }

  useEffect(() => {
    getOrderDetails(id);
  }, [getOrderDetails, id]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let date = dayjs(schedule).format('MMM DD YYYY');
  let time = dayjs(schedule).format('h a');

  return (
    <Layout>
      <ProfileStyle>
        <div className="info">
          {loading ? (
            <Loaders>
              <Spin indicator={antIcon} />,
            </Loaders>
          ) : (
            <>
              {pickupCoordinates && <Map pickup={pickup} deliver={deliver} />}
              <h1>Order Information</h1>
              <h3>Address Information</h3>
              <div className="details">
                <div className="address">
                  <img src={locator} alt="Locate icon" />
                  {detail && (
                    <div className="add-details">
                      <div className="add">
                        <div>{pickupAddress}</div>

                        <div className="contact">
                          <div>
                            <FaUser /> {`${pickupFirstName} ${pickupSurname}`}
                          </div>
                          <div>
                            <FaPhoneAlt /> {`${pickupPhone}`}
                          </div>
                        </div>
                      </div>

                      <div className="add">
                        <div>{deliveryAddress}</div>
                        <div className="contact">
                          <div>
                            <FaUser />{' '}
                            {`${deliveryFirstName} ${deliverySurname}`}
                          </div>
                          <div>
                            <FaPhoneAlt /> {`${deliveryPhone}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="schedule">
                  <div>
                    <FaRegClock /> <span>{time}</span>
                  </div>
                  <div>
                    <FaRegCalendar /> <span>{date}</span>
                  </div>
                  <div>
                    <FiTruck />{' '}
                    <span>
                      {deliveryType === 1
                        ? 'Same Day'
                        : deliveryType === 2
                        ? 'Next Day'
                        : 'Scheduled'}
                    </span>
                  </div>
                  <div>
                    <span className="desc">Price:</span> &#8358;{priceText}
                  </div>
                  <div>
                    <span className="desc">Payment Method:</span>{' '}
                    {isCashTransaction ? 'Cash' : 'Card'}
                  </div>
                </div>
              </div>

              <h3>User Information</h3>
              <div className="details">
                {detail.user && (
                  <div className="user">
                    <div>
                      <FaUser />{' '}
                      {`${detail.user.firstName} ${detail.user.surname}`}
                    </div>
                    <div>
                      <FaPhoneAlt /> {`${detail.user.phone}`}
                    </div>
                    <div>
                      <FiMail /> {`${detail.user.email}`}
                    </div>
                  </div>
                )}
              </div>

              <h3>Trouper Information</h3>
              <div className="details">
                {detail.trooper ? (
                  <div className="trooper">
                    <div className="trooper-info">
                      <div>
                        <FaUser />{' '}
                        {`${detail.trooper.User.firstName} ${detail.trooper.User.surname}`}
                      </div>
                      <div>
                        <FaPhoneAlt /> {`${detail.trooper.User.phone}`}
                      </div>
                      <div>
                        <FiMail /> {`${detail.trooper.User.email}`}
                      </div>
                    </div>
                    <div className="img">
                      <img
                        src={detail.trooper.User.imageUrl}
                        alt={detail.trooper.User.firstName}
                      />
                    </div>
                  </div>
                ) : (
                  <div>Not assigned to a trouper yet</div>
                )}
              </div>
            </>
          )}
        </div>
      </ProfileStyle>
    </Layout>
  );
};

const mapStateToProps = ({ orderReducer }) => ({
  loading: orderReducer.loading,
  detail: orderReducer.detail,
  error: orderReducer.error,
});

export default connect(mapStateToProps, {
  getOrderDetails,
})(OrderDetail);
