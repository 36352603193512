import React from 'react';
import { Link } from 'react-router-dom';
import google from '../../assets/google-play-badge.png';
import apple from '../../assets/apple.png';
import ela from '../../assets/vid.m4v';
import thumbnail from '../../assets/ela_vid.png';
import depend from '../../assets/depend.svg';
import efficiency from '../../assets/efficiency.svg';
import friend from '../../assets/friend.svg';
import simple from '../../assets/simple.svg';
import innovation from '../../assets/innovation.svg';
import bag from '../../assets/bag.svg';
import kg from '../../assets/kg.svg';
import app from '../../assets/app.svg';
import pro from '../../assets/pro.svg';

import { HomeStyles } from '../styles/Home';
import HomeCards from '../../components/Landing/HomeCards';
import Footer from '../../components/Landing/Footer';
import HomeNav from '../../components/Landing/HomeNav';

const Home = () => {
  const about = [
    {
      title: 'Dependable',
      icon: depend,
      text:
        'When you make the choice to go with us, our motto is “No regrets” because we aim to please and get your deliveries to the right destination promptly.',
    },
    {
      title: 'Efficient',
      icon: efficiency,
      text: 'We get it done rightly, the very first time.',
    },
    {
      title: 'Professional',
      icon: pro,
      text:
        'From our customer-care representatives on the phone to the Troupers who make deliveries, our staffs are trained to be courteous and handle your valuables and business with the utmost care.',
    },
    {
      title: 'Innovative',
      icon: innovation,
      text:
        'We believe in the use of technology to deliver value at every point to you.',
    },
    {
      title: 'Friendly',
      icon: friend,
      text:
        'We’d like for you to see us as your friendly next-door neighbor who not only runs your errands, but always greets you with a smile.',
    },
    {
      title: 'Simple',
      icon: simple,
      text:
        'We know you’re busy. Our goal is to take some of that load off you by simplifying our processes.',
    },
  ];

  const services = [
    {
      title: 'Same Day Delivery',
      desc:
        'The future is here with speedy same day deliveries. Send parcel(s) within the city of Lagos and it’s environs and they will be delivered within hours.',
    },
    {
      title: 'Next Day Delivery',
      desc:
        'Missed the deadline for Same Day delivery? Don’t worry, we have you covered. We’ll pickup and deliver your parcel the very next day.',
    },
    {
      title: 'Bulk Delivery',
      desc:
        'We have vans that carry up to 3.5 metric tons for moving houses, offices etc.',
    },
  ];

  return (
    <HomeStyles>
      <HomeNav />
      <section className="hero">
        <div className="text">
          <h2>optimize your Pickups and deliveries today</h2>
          <div>
            <p>
              Smart solutions for all your delivery requirements at your finger
              tips - any day, any time, anywhere
            </p>
          </div>
          <div className="download">
            <div>
              <img src={apple} alt="Apple download badge" />
            </div>
            <div>
              <img src={google} alt="Google download badge" />
            </div>
          </div>
        </div>
      </section>

      <section className="about" id="About Us">
        <div className="video">
          <video
            src={ela}
            type="video/mov"
            height="400"
            controls="controls"
            poster={thumbnail}
          />
        </div>
        <div className="text">
          <h2>About Us</h2>
          <div className="underline"></div>
          <div>
            <p>
              Express logistics agency (ELA) ltd. is a fast growing logistics
              company headquartered in Lagos, Nigeria. We provide last mile
              courier (dispatch) solutions for moving parcels,letters,
              groceries, pharmaceticals and more.
            </p>
            <p>
              For moving houses, offices, general merchandize for SMEs, our
              truck fleet is available for inter- and intracity trips. Also
              available are our airfreight solutions for international shipments
              from the United Kingdom (UK) and the United States (US).
            </p>
          </div>
        </div>
      </section>

      <section className="services" id="Services">
        <h2>Our Services</h2>
        <div className="underline"></div>
        <div className="cards">
          {services.map((item, idx) => (
            <div className="card" key={idx}>
              <h2>{item.title}</h2>
              <div className="content">
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="walkthrough">
        <div className="desc">
          <h2>Let’s walk you through</h2>
          <p>
            Whether you are a business owner or a courier, our user-friendly
            interface will help you to manage your parcels in a minute. Fill our
            box with as many parcels as you want for the same price. We charge
            by delivery, not by parcel.
          </p>
          <div className="mid">
            <div className="parcel">
              <img src={kg} alt="kg logo" />
              <div>
                <p>Max weight</p>
                <p>20 kg</p>
              </div>
            </div>
            <div className="parcel">
              <img src={bag} alt="box logo" />
              <div>
                <p>Bag dimensions</p>
                <p>0.5m x 0.5m</p>
              </div>
            </div>
          </div>
          <Link to="/how">How it Works</Link>
        </div>
      </section>

      <section className="why" id="Why ELA">
        <h2>Why Choose ELA</h2>
        <div className="underline"></div>
        <div>
          We at ELA offer top notch and quality services to help get your
          deliverables at your door step. We are:
        </div>
        <div className="cards">
          {about.map((item, idx) => (
            <HomeCards
              title={item.title}
              icon={item.icon}
              text={item.text}
              key={idx}
            />
          ))}
        </div>
      </section>

      <section className="download-app">
        <div className="order">
          <div className="vertical">Ready to order?</div>
          <div className="app">
            <img src={app} alt="screenshot of app to download" />
            <h2>Download ELA App Today</h2>
            <div className="store">
              <div>
                <img src={apple} alt="Apple download badge" />
              </div>
              <div>
                <img src={google} alt="Google download badge" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </HomeStyles>
  );
};

export default Home;
