import React from 'react';
import { TrooperView } from './../styles/trooper';
import SideNav from '../../components/Admin/SideNavBar';
import NavBar from '../../components/Admin/NavBar';

const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <TrooperView>
        <SideNav />
        {children}
      </TrooperView>
    </>
  );
};

export default Layout;
