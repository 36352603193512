import React from 'react';
import { connect } from 'react-redux';
import { Table, Space, Avatar } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { rejectTrooper } from '../../../state/actions/troopers';
import { Link } from 'react-router-dom';
import { DeleteTwoTone } from '@ant-design/icons';

const { Column } = Table;

const Requests = ({ loading, troopers, getTrooperRequests }) => {
  const { troopers: data } = troopers;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <Table
        dataSource={data}
        rowKey="id"
        pagination={{
          position: 'bottomLeft',
          defaultPageSize: 10,
          total: troopers ? troopers.totalItems : '',
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          onChange: (page, size) => {
            let currentNo = page - 1;
            getTrooperRequests(currentNo);
          },
        }}
        rowClassName="row"
        loading={{ spinning: loading, indicator: antIcon }}
        onChange={(page, size) => {
          let currentNo = page - 1;
          getTrooperRequests(currentNo);
        }}
      >
        <Column
          title="Full Name"
          render={(item, record) => (
            <Space size="large" align="baseline">
              <Avatar size="large" src={item.imageUrl} alt={item.firstName} />
              <h3>
                {item.firstName} {item.surname}
              </h3>
            </Space>
          )}
        />
        <Column title="D.O.B" dataIndex="dateOfBirth" />
        <Column title="Phone" dataIndex="phone" />
        <Column title="Email Address" dataIndex="email" />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <div className="action">
              <Link to={`/requests/trooper/${text.id}`}>
                <button>View Details</button>
              </Link>
              <DeleteTwoTone
                twoToneColor="#eb2f96"
                onClick={() => rejectTrooper(text.id)}
              />
            </div>
          )}
        />
      </Table>
    </>
  );
};

export default connect(null, { rejectTrooper })(Requests);
