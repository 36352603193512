import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getDeliveredOrders } from '../../state/actions/orders';
import { MainViewStyle } from '../../components/styles/MainView';
import SearchBar from '../../components/Admin/SearchBar';
import Layout from './Layout';
import Delivered from '../../components/Admin/Orders/Delivered';

const DeliveredOrders = ({ getDeliveredOrders, loading, deliveries }) => {
  useEffect(() => {
    getDeliveredOrders();
  }, [getDeliveredOrders]);
  return (
    <Layout>
      <MainViewStyle>
        <h1>Delivered Orders</h1>
        <SearchBar />
        <Delivered loading={loading} deliveries={deliveries} />
      </MainViewStyle>
    </Layout>
  );
};

const mapStateToProps = ({ orderReducer }) => ({
  loading: orderReducer.loading,
  deliveries: orderReducer.deliveries,
  error: orderReducer.error,
});

export default connect(mapStateToProps, {
  getDeliveredOrders,
})(DeliveredOrders);
