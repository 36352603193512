import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input, Button, notification, Checkbox } from 'antd';
import Layout from './Layout';
import { FormStyle, LoginStyle } from '../styles/Form';
import logo from '../../assets/ELA_logo.JPG';
import { adminLogin } from '../../state/actions/admin';

const { Item } = Form;

const Login = ({ adminLogin }) => {
  let history = useHistory();

  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (values) => {
    try {
      const admin = await adminLogin(values);
      if (admin.status !== 200) {
        notification['error']({
          message: 'Error',
          description: admin.message,
          duration: 5,
        });
      } else {
        history.push('/dashboard');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <LoginStyle>
        <FormStyle>
          <div className="logo">
            <img src={logo} alt="ELA Logo" />
            <h2>Welcome Back</h2>
          </div>
          <Form
            layout="vertical"
            className="register"
            initialValues={initialValues}
            onFinish={onSubmit}
            scrollToFirstError
          >
            <Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail.',
                },
                {
                  required: true,
                  message: 'Please input your E-mail.',
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password.',
                },
                { min: 8, message: 'Passsword must be minimum 8 characters.' },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Item>
            <Item className="space">
              <Item noStyle>
                <Checkbox>Remember me</Checkbox>
              </Item>

              <Link to="/find-email">Forgot password?</Link>
            </Item>
            <Item>
              <Button htmlType="submit" block>
                Next
              </Button>
              <div className="link">
                New User? <Link to="/register">Sign Up</Link>
              </div>
            </Item>
          </Form>
        </FormStyle>
      </LoginStyle>
    </Layout>
  );
};

const mapStateToProps = ({ adminReducer }) => ({
  loading: adminReducer.loading,
  user: adminReducer.user,
  error: adminReducer.error,
});

export default connect(mapStateToProps, {
  adminLogin,
})(Login);
