import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { ImBubble2 } from 'react-icons/im';
import { FaUser, FaUsers, FaRegUser } from 'react-icons/fa';
import { FiHome, FiBox } from 'react-icons/fi';
import { SideNavStyle } from '../styles/Nav';

const { SubMenu } = Menu;

const items = [
  { id: 1, title: 'Home', icon: <FiHome />, link: '/dashboard' },
  {
    id: 2,
    title: 'Troupers',
    icon: <FaRegUser />,
    link: '/troopers',
    sublinks: [
      { id: 3, title: 'New Request', link: '/requests' },
      { id: 4, title: 'Accepted Troupers', link: '/troopers' },
    ],
  },
  { id: 5, title: 'Users', icon: <FaUsers />, link: '/users' },
  {
    id: 6,
    title: 'Orders',
    icon: <FiBox />,
    link: '/orders',
    sublinks: [
      { id: 7, title: 'Pending Orders', link: '/orders/pending' },
      { id: 8, title: 'Delivered Orders', link: '/orders/delivered' },
    ],
  },
  { id: 9, title: 'Announcement', icon: <ImBubble2 />, link: '/announcement' },
  { id: 10, title: 'Edit Profile', icon: <FaUser />, link: '/edit' },
];

const SideNav = () => {
  return (
    <SideNavStyle>
      <Menu style={{ width: 250, height: '100vh' }} mode="inline">
        {items.map((item) => {
          return item.sublinks ? (
            <SubMenu
              key={item.id}
              title={
                <span>
                  {item.icon}
                  <span>{item.title}</span>
                </span>
              }
            >
              {item.sublinks.map((sub) => (
                <Menu.Item key={sub.id} className="sub">
                  <NavLink to={sub.link} activeClassName="selected">
                    {sub.title}
                  </NavLink>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.id}>
              <NavLink to={item.link} activeClassName="selected">
                {item.icon}
                <span>{item.title}</span>
              </NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    </SideNavStyle>
  );
};

export default SideNav;
