import React from 'react';
import { HomeCardStyle } from './../styles/HomeCard';

const HomeCards = ({ title, icon, text }) => {
  return (
    <HomeCardStyle>
      <div className="icon">
        <img src={icon} alt={title} />
      </div>
      <div className="content">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </HomeCardStyle>
  );
};

export default HomeCards;
