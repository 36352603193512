import { apiURL } from '../../config';
import { axiosWithAuth } from '../../utils/axiosWithAuth';

export const TROOPERS_LOADING = 'TROOPERS_LOADING';
export const ACCEPT_TROOPER_LOADING = 'ACCEPT_TROOPER_LOADING';
export const GET_TROOPERS_SUCCESS = 'GET_TROOPERS_SUCCESS';
export const GET_TROOPER_SUCCESS = 'GET_TROOPER_SUCCESS';
export const GET_ACCEPTED_TROOPERS_SUCCESS = 'GET_ACCEPTED_TROOPERS_SUCCESS';
export const GET_ACCEPTED_TROOPER_SUCCESS = 'GET_ACCEPTED_TROOPER_SUCCESS';
export const ACCEPT_TROOPER_SUCCESS = 'ACCEPT_TROOPER_SUCCESS';
export const REJECT_TROOPER_SUCCESS = 'REJECT_TROOPER_SUCCESS';
export const UPDATE_TROOPER_SUCCESS = 'UPDATE_TROOPER_SUCCESS';
export const GET_TROOPERS_FAILURE = 'GET_TROOPERS_FAILURE';
export const RESET_TROOPERS = 'RESET_TROOPERS';

export const getTrooperRequests = (page = 0) => async (dispatch) => {
  dispatch({ type: TROOPERS_LOADING });
  try {
    const res = await axiosWithAuth().get(
      `${apiURL}/admin/trooper-requests?page=${page}`
    );
    dispatch({ type: GET_TROOPERS_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({
      type: GET_TROOPERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};

export const getTrooperProfile = (id) => async (dispatch) => {
  dispatch({ type: TROOPERS_LOADING });
  try {
    const res = await axiosWithAuth().get(
      `${apiURL}/admin/trooper-profile/${id}`
    );
    dispatch({ type: GET_TROOPER_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({
      type: GET_TROOPERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};

export const acceptTrooperProfile = (id) => async (dispatch) => {
  dispatch({ type: ACCEPT_TROOPER_LOADING });
  try {
    const res = await axiosWithAuth().post(
      `${apiURL}/admin/accept-trooper/${id}`
    );
    dispatch({ type: ACCEPT_TROOPER_SUCCESS, payload: res.data.data });
    return res;
  } catch (error) {
    dispatch({
      type: GET_TROOPERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};

export const rejectTrooper = (id) => async (dispatch) => {
  dispatch({ type: TROOPERS_LOADING });
  try {
    const res = await axiosWithAuth().delete(
      `${apiURL}/admin/reject-trooper/${id}`
    );
    dispatch({ type: REJECT_TROOPER_SUCCESS, payload: res.data });

    return res;
  } catch (error) {
    dispatch({
      type: GET_TROOPERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};

export const getAcceptedTroopers = (page = 0) => async (dispatch) => {
  try {
    dispatch({ type: TROOPERS_LOADING });
    const res = await axiosWithAuth().get(
      `${apiURL}/admin/troopers?page=${page}`
    );
    dispatch({ type: GET_ACCEPTED_TROOPERS_SUCCESS, payload: res.data.data });
    return res;
  } catch (error) {
    dispatch({
      type: GET_TROOPERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error.response?.data;
  }
};

export const resetTrooper = () => async (dispatch) => {
  dispatch({ type: RESET_TROOPERS });
};

export const getAcceptedTrooperProfile = (id) => async (dispatch) => {
  dispatch({ type: TROOPERS_LOADING });
  try {
    const res = await axiosWithAuth().get(`${apiURL}/admin/trooper/${id}`);
    dispatch({ type: GET_ACCEPTED_TROOPER_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({
      type: GET_TROOPERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};

export const updateTrooperStatus = (id, active) => async (dispatch) => {
  dispatch({ type: TROOPERS_LOADING });
  try {
    const res = await axiosWithAuth().put(
      `${apiURL}/admin/trooper/${id}`,
      active
    );

    dispatch({ type: UPDATE_TROOPER_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({
      type: GET_TROOPERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};
