import React from 'react';
import { FormLayoutStyle } from '../styles/Form';

const Layout = ({ children }) => {
  return (
    <FormLayoutStyle>
      <div className='container'>{children}</div>
    </FormLayoutStyle>
  );
};

export default Layout;
