import React from 'react';
import {
  GoogleMap,
  LoadScriptNext,
  DirectionsService,
} from '@react-google-maps/api';

const Map = ({ pickup, deliver }) => {
  return (
    <LoadScriptNext googleMapsApiKey="">
      <GoogleMap
        id="map"
        zoom={15}
        mapContainerStyle={{ height: 200, width: '100%' }}
        center={{
          lat: Number(pickup.lat),
          lng: Number(pickup.lng),
        }}
      >
        <DirectionsService
          options={{
            origin: {
              lat: Number(pickup.lat),
              lng: Number(pickup.lng),
            },
            destination: {
              lat: Number(deliver.lat),
              lng: Number(deliver.lng),
            },
            travelMode: 'DRIVING',
          }}
        />
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map;
