import styled from 'styled-components';

export const MainViewStyle = styled.div`
  margin: 50px 30px;
  width: calc(100% - 300px);
  h1 {
    font-size: 26px;
    font-weight: bolder;
  }
  .heading {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
    padding: 10px;

    h4 {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
  }

  .ant-pagination {
    margin: 45px 0 0 70px;
  }
  .search {
    display: flex;
    justify-content: flex-end;
  }

  .ant-table-wrapper {
    thead {
      th {
        font-size: 16px;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }

    td {
      font-size: 14px;
      .action {
        button {
          padding: 10px;
          background: #166cdd;
          color: #fff;
          box-shadow: 0px 2px 6px rgba(34, 34, 36, 0.15);
          border-radius: 12px;
          margin-right: 10px;
        }
      }

      .ant-tag {
        font-size: 14px;
        padding: 5px;
        text-transform: uppercase;
      }

      .order-actions {
        display: flex;
        justify-content: space-between;
        .address {
          display: flex;
          align-items: center;
          margin-right: 20px;
          span {
            width: 130px;
          }
          svg {
            margin: 0 10px;
            font-size: 18px;
          }
        }
      }
    }
  }
`;
