import styled from 'styled-components';

export const ProfileStyle = styled.div`
  margin: 10px;
  width: 100%;
  h1 {
    margin: 30px 0;
    font-size: 26px;
    font-weight: bolder;
  }
  .info {
    width: 80%;
    margin: 0 auto;
    h3 {
      background-color: #166cdd;
      text-transform: uppercase;
      color: white;
      padding: 15px 40px;
      border-radius: 10px 10px 0px 0px;
      font-weight: bolder;
      margin-top: 20px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background-color: #fff;
      svg {
        color: #166cdd;
        margin-right: 10px;
      }
      .address {
        display: flex;
        img {
          width: 50px;
        }

        .add-details {
          height: 245px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .contact {
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 20px 0;
        }
      }

      .schedule {
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .user {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 150px;
      }

      .trooper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .img {
          img {
            border-radius: 50%;
            height: 160px;
            width: 160px;
          }
        }
        .trooper-info {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 150px;
        }
      }

      .text {
        h2 {
          color: #9fa9bc;
          text-transform: uppercase;
        }
        div {
          color: #1f1f20;
          margin: 5px 0 35px 0;
        }
      }
      img {
        border-radius: 50%;
        height: 160px;
        width: 160px;
      }

      .desc {
        color: #aca8a8;
        font-weight: bolder;
      }
    }
  }

  .guarantors {
    margin: 20px auto;
    h3 {
      background-color: #166cdd;
      text-transform: uppercase;
      color: white;
      padding: 15px 40px;
      border-radius: 10px 10px 0px 0px;
      font-weight: bolder;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;

    button {
      border-radius: 12px;
      padding: 15px 20px;
      width: 160px;
      margin: 0 10px;
      font-weight: bold;
      &:first-child {
        border: 1px solid #166cdd;
        background: #fff;
        color: #166cdd;
      }
      &:nth-child(2) {
        background: #166cdd;
        color: #fff;
      }
      &:disabled {
        background: #9fa9bc;
      }
    }
  }

  .orders {
    margin: 20px auto;
    h3 {
      background-color: #166cdd;
      text-transform: uppercase;
      color: white;
      padding: 15px 40px;
      border-radius: 10px 10px 0px 0px;
      font-weight: bolder;
    }
  }
`;
