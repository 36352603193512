import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getAcceptedTroopers,
  resetTrooper,
} from '../../state/actions/troopers';
import { MainViewStyle } from '../../components/styles/MainView';
import SearchBar from '../../components/Admin/SearchBar';
import Layout from './Layout';
import Troopers from '../../components/Admin/Troopers/Troopers';

const Dashboard = ({
  getAcceptedTroopers,
  loading,
  loader,
  acceptedTroopers,
  resetTrooper,
}) => {
  useEffect(() => {
    getAcceptedTroopers();
    return () => resetTrooper();
  }, [getAcceptedTroopers, resetTrooper]);
  return (
    <Layout>
      <MainViewStyle>
        <h1>Accepted Trooper's List</h1>
        <SearchBar />
        <Troopers
          acceptedTroopers={acceptedTroopers}
          getAcceptedTroopers={getAcceptedTroopers}
          loading={loading}
        />
      </MainViewStyle>
    </Layout>
  );
};

const mapStateToProps = ({ trooperReducer }) => ({
  loading: trooperReducer.loading,
  loader: trooperReducer.loader,
  acceptedTroopers: trooperReducer.acceptedTroopers,
  error: trooperReducer.error,
});

export default connect(mapStateToProps, {
  getAcceptedTroopers,
  resetTrooper,
})(Dashboard);
