import {
  ORDERS_LOADING,
  GET_PENDING_ORDERS_SUCCESS,
  GET_DELIVERED_ORDERS_SUCCESS,
  GET_ORDER_DETAIL_SUCCESS,
  GET_PENDING_ORDERS_FAILURE,
  GET_DELIVERED_ORDERS_FAILURE,
  GET_ORDER_DETAIL_FAILURE,
} from '../actions/orders';

export const initialState = {
  loading: false,
  pending: {},
  deliveries: {},
  detail: {},
  error: '',
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_LOADING:
      return { ...state, loading: true };
      
    case GET_PENDING_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        pending: action.payload,
      };

    case GET_DELIVERED_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveries: action.payload,
      };

    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: action.payload,
      };

    case GET_PENDING_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_DELIVERED_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default orderReducer;
