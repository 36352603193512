import styled from 'styled-components';
import hero from '../../assets/hero.png';
import after from '../../assets/after.svg';
import walkthrough from '../../assets/walkthrough.svg';
import background from '../../assets/background.svg';

export const HomeStyles = styled.div`
  .hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url(${hero});
    background-repeat: no-repeat;
    background-size: contain;
    margin: 20px;

    .text {
      margin: 300px 0 30px 0;
      line-height: 2;
      text-align: center;
      h2 {
        font-size: 28px;
        font-weight: bolder;
        text-transform: capitalize;
        margin: 10px 0;
      }
      div {
        line-height: 2;
        font-size: 14px;
      }
      .download {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        width: 100%;
        div {
          img {
            width: 80%;
            height: 100%;
          }
        }
      }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      background-size: 500px;
      background-position: right 50% top;

      .text {
        width: 100%;
        margin: 400px 0 20px 0;
        text-align: center;
        h2 {
          font-size: 32px;
        }
        div {
          font-size: 18px;
        }
        .download {
          flex-direction: row;
          width: 300px;
          margin: 30px auto;
          div {
            width: 300px;
            height: 60px;
          }
        }
      }

      &:before {
        content: url(${after});
        position: absolute;
        top: 60%;
        margin-left: -150px;
      }
    }

    @media (min-width: 1024px) {
      background-size: 600px;
      margin: auto 40px;
      margin-bottom: 50px;
      height: 700px;
      background-position: right 0 top 50%;

      .text {
        width: 420px;
        margin: 0;
        text-align: left;
        .download {
          flex-direction: row;
          width: 100%;
          div {
            width: 300px;
            height: 60px;
          }
        }
      }

      &:before {
        z-index: -1;
        content: url(${after});
        position: absolute;
        top: 70%;
        margin-left: -120px;
      }
    }

    @media (min-width: 1440px) {
      margin: auto 100px;
      background-size: contain;
      .text {
        h2 {
          font-size: 32px;
        }
        div {
          font-size: 18px;
        }
        .download {
          flex-direction: row;
        }
      }

      &:before {
        margin-left: -120px;
        bottom: 0;
      }
    }
  }

  .about {
    margin: 20px;
    display: flex;
    flex-direction: column;

    .video {
      video {
        width: 100%;
      }
    }
    .text {
      text-align: center;
      margin: 30px 0;
      h2 {
        font-weight: bolder;
        padding-bottom: 5px;
        font-size: 24px;
      }
      .underline {
        width: 117px;
        border-bottom: 5px solid #ffbe0c;
        margin: 0 auto;
      }

      p {
        margin: 30px 0;
        line-height: 2;
        font-size: 14px;
        text-transform: capitalize;
      }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      margin: 50px;
      .video {
        margin: 30px auto;
        video {
          width: 400px;
        }
      }
      .text {
        h2 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
      }
    }

    @media (min-width: 1024px) {
      margin: 50px 40px;
      .video {
        margin-right: 30px;
        video {
          width: 400px;
        }
      }
      flex-direction: row;
      .text {
        width: 650px;
        text-align: left;
        margin: 10px 0;
        h2 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
        .underline {
          margin-left: 15px;
        }
      }
    }
    @media (min-width: 1440px) {
      margin: 200px 100px 100px 100px;
      .text {
        h2 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }

  .services {
    margin: 100px 30px;
    display: flex;
    flex-direction: column;

    h2 {
      color: #04172a;
      font-size: 32px;
      font-weight: bolder;
      text-align: center;
      margin: 10px 0;
    }
    .underline {
      width: 117px;
      border-bottom: 5px solid #ffbe0c;
      align-self: center;
    }
    .cards {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .card {
        width: 350px;
        background: #fff;
        box-shadow: 0px 16px 32px rgba(245, 245, 245, 0.8);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 30px;
        margin: 20px 0%;
        h2 {
          font-size: 24px;
          font-weight: bolder;
          margin: 30px 0 10px 0;
        }
        .content {
          height: 150px;
          margin: 10px 0;
          p {
            font-size: 14px;
            line-height: 2;
            text-align: center;
            color: #828282;
          }
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          width: 32%;
          height: auto;
          padding: 10px;
          h2 {
            font-size: 24px;
            margin: 20px 0;
          }
          .content {
            height: 200px;
            p {
              font-size: 16px;
            }
          }
        }
        @media (min-width: 1440px) {
          width: 30%;
          h2 {
            font-size: 32px;
          }
          .content {
            margin: 30px 0;
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin: 100px 30px;
      .underline {
        margin-bottom: 50px;
      }
    }

    @media (min-width: 1024px) {
      margin: 150px 80px;
    }
  }

  .walkthrough {
    background-image: url(${walkthrough});
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #ffbe0c;
    display: flex;
    margin: 100px 0;

    .desc {
      margin: 520px 10px 10px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      h2 {
        font-size: 24px;
        font-weight: bolder;
        margin: 50px 0 10px 0;
        text-align: center;
      }
      > p {
        font-size: 14px;
        line-height: 2;
        margin: 30px 0;
      }

      .mid {
        display: flex;
        justify-content: space-evenly;
        margin: 30px 8px;

        .parcel {
          display: flex;
          width: 140px;
          img {
            height: 48px;
            width: 48px;
            margin-right: 10px;
          }
          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;

            p {
              font-size: 14px;
              &:nth-child(2) {
                font-weight: bolder;
              }
            }
          }
        }
      }
      a {
        text-align: center;
        width: 200px;
        padding: 20px;
        background: #166cdd;
        box-shadow: 0px 2px 6px rgba(34, 34, 36, 0.15);
        border-radius: 12px;
        font-size: 18px;
        color: #fff;
        margin: 30px auto;
      }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      height: 850px;
      background-size: 300px;
      background-position: right 50% top;

      .desc {
        align-items: center;
        text-align: center;
        width: 700px;
        margin: 600px auto;
        h2 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
        .mid {
          .parcel {
            font-size: 16px;
            width: 180px;
          }
        }

        a {
          margin: 20px 0;
        }
      }
    }

    @media (min-width: 1024px) {
      height: 650px;
      flex-direction: row;
      background-position: left 10px bottom -10px;
      background-size: 450px;
      margin-bottom: 150px;
      .desc {
        width: 700px;
        margin: 0 0 0 430px;
        text-align: left;
        padding: 0 20px;
        h2 {
          text-align: left;
          margin: 30px 0;
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }

        .mid {
          justify-content: space-between;
          .parcel {
            font-size: 16px;
            width: 50%;
          }
        }
        a {
          margin: 50px 0 0 0;
        }
      }
    }
  }

  .why {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    h2 {
      color: #04172a;
      font-size: 28px;
      font-weight: bolder;
      text-align: center;
      margin: 10px 0;
    }
    .underline {
      width: 117px;
      border-bottom: 5px solid #ffbe0c;
      margin-bottom: 30px;
    }
    div {
      line-height: 2;
      text-align: center;
      font-size: 14px;
    }
    .cards {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;

      @media (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      margin: 50px;
      h2 {
        font-size: 32px;
      }
      div {
        font-size: 18px;
      }
    }

    @media (min-width: 1024px) {
      margin: 120px;
      h2 {
        font-size: 32px;
      }
      div {
        font-size: 18px;
      }
    }

    @media (min-width: 1440px) {
      margin: 120px;
      h2 {
        font-size: 32px;
      }
      div {
        font-size: 18px;
      }
    }
  }

  .download-app {
    margin: 50px 0 0 0;
    height: 900px;
    width: 100%;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .order {
      display: flex;
      justify-content: space-evenly;
      position: relative;
      flex-direction: column;

      .vertical {
        color: #fff;
        font-size: 28px;
        font-weight: bolder;
        margin: 10px 0;
        text-align: center;
      }

      .app {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-size: 24px;
          font-weight: bolder;
          color: #fff;
          margin: 30px 0;
        }

        .store {
          display: flex;
          margin: 15px 0;
          width: 100%;
          margin-bottom: 65px;
          div {
            margin: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      margin: 200px 0 0 0;
      .order {
        flex-direction: row;
        .vertical {
          position: absolute;
          top: 40%;
          left: 0;
          transform: rotate(270deg);
        }

        .store {
          div {
            width: 178px;
            height: 56px;
            margin-right: 10px;
          }
        }
      }
    }
  }
`;
