import styled from 'styled-components';
import form from '../../assets/form.svg';
import back from '../../assets/back.svg';

export const FormLayoutStyle = styled.div`
  background: linear-gradient(
      0deg,
      rgba(31, 31, 32, 0.45),
      rgba(31, 31, 32, 0.45)
    ),
    url(${form});
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;
  width: 100wh;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background: url(${back});
    background-repeat: no-repeat;
    background-size: cover;
    width: 600px;
    height: 750px;
    opacity: 1;
    border-radius: 10px;
  }
`;

export const FormStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    img {
      margin: 30px 0 10px 0;
      height: 50px;
    }
    h2 {
      font-size: 24px;
      font-weight: bolder;
    }
  }

  .register {
    width: 500px;
    input[type='text'] {
      background: rgba(177, 177, 177, 0.1);
      border-radius: 20px;
      border: none;
      padding: 12px;
      outline: none;
    }
    .ant-input-password {
      background: rgba(177, 177, 177, 0.1);
      border-radius: 20px;
      border: none;
      padding: 12px;
      outline: none;
      input {
        background: rgba(177, 177, 177, 0);
      }
    }
    button[type='submit'] {
      background: #166cdd;
      color: #fff;
      box-shadow: 0px 8px 16px rgba(34, 34, 74, 0.25);
      border-radius: 20px;
      height: 50px;
      font-size: 20px;
      font-weight: bolder;
      border: none;
    }
    .link {
      text-align: center;
      margin: 15px 0;
      a {
        color: #166cdd;
        cursor: pointer;
      }
    }
  }
`;

export const LoginStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0 0;

  form {
    margin-top: 80px;
  }
  .space .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between !important;

    a {
      color: #166cdd;
      cursor: pointer;
    }
  }
`;

export const VerifyEmailStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 130px 0 0;
  h3 {
    font-size: 28px;
    font-weight: bolder;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 100px;
  }

  .verify {
    justify-content: center;
    div {
      width: 15%;
      margin: 0 10px;
    }
    .inputStyle {
      border-radius: 12px;
      border: 1px solid #dadada;
      width: 100% !important;
      padding: 40px 0;
      color: #1f1f20;
      font-size: 32px;
    }
  }

  .bottom {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button[type='submit'] {
    background: #166cdd;
    color: #fff;
    box-shadow: 0px 8px 16px rgba(34, 34, 74, 0.25);
    border-radius: 20px;
    height: 50px;
    width: 530px;
    font-size: 20px;
    font-weight: bolder;
    border: none;
  }
`;

export const ResetStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 130px 0 0;

  h2 {
    font-size: 28px;
    font-weight: bolder;
    margin-bottom: 30px;
    color: #253855;
  }
  p {
    margin: 30px 0 40px 0;
  }
  input[type='text'] {
    width: 500px;
    background: rgba(177, 177, 177, 0.1);
    border-radius: 20px;
    border: none;
    padding: 12px;
    outline: none;
  }

  button[type='submit'] {
    background: #166cdd;
    color: #fff;
    box-shadow: 0px 8px 16px rgba(34, 34, 74, 0.25);
    border-radius: 20px;
    height: 50px;
    font-size: 20px;
    font-weight: bolder;
    border: none;
  }
  .link {
    text-align: center;
    margin: 15px 0;
    a {
      color: #166cdd;
      cursor: pointer;
    }
  }
`;

export const PasswordStyle = styled.div`
  margin: 100px 0 0 0;

  .logo {
    margin-bottom: 70px;
    p {
      margin: 20px 0;
    }
  }
`;
