import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPendingOrders } from '../../state/actions/orders';
import { MainViewStyle } from '../../components/styles/MainView';
import SearchBar from '../../components/Admin/SearchBar';
import Layout from './Layout';
import Pending from '../../components/Admin/Orders/Pending';

const PendingOrders = ({ getPendingOrders, loading, pending }) => {
  useEffect(() => {
    getPendingOrders();
  }, [getPendingOrders]);
  return (
    <Layout>
      <MainViewStyle>
        <h1>Pending Orders</h1>
        <SearchBar />
        <Pending loading={loading} pending={pending} />
      </MainViewStyle>
    </Layout>
  );
};

const mapStateToProps = ({ orderReducer }) => ({
  loading: orderReducer.loading,
  pending: orderReducer.pending,
  error: orderReducer.error,
});

export default connect(mapStateToProps, {
  getPendingOrders,
})(PendingOrders);
