import styled from 'styled-components';

export const HomeCardStyle = styled.div`
  box-shadow: 0px 16px 32px rgba(245, 245, 245, 0.8);
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;

  .icon {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background: rgba(255, 190, 12, 0.3);
    box-shadow: 0px 8px 16px rgba(255, 187, 2, 0.25);

    img {
      margin: 21%;
    }
  }
  .content {
    height: 200px;
    margin: 15px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      margin: 10px 0;
      color: #828282;
      line-height: 2;
      text-align: center;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 50%;
    .content {
      p {
        font-size: 16px;
      }
      height: 300px;
      margin: 20px;
    }
  }

  @media (min-width: 1024px) {
    width: 32%;
    height: 400px;

    .content {
      margin: 15px 10px;

      h2 {
        font-size: 18px;
        font-weight: bolder;
      }
      p {
        font-size: 14px;
      }
    }
  }

  @media (min-width: 1440px) {
    .content {
      h2 {
        font-size: 24px;
        font-weight: bolder;
      }
      p {
        font-size: 18px;
      }
    }
  }
`;
