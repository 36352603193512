import React from 'react';
import { FaUsers, FaRegUser } from 'react-icons/fa';
import { FiBox } from 'react-icons/fi';
import { DashboardStyle } from './../styles/Dashboard';

const DashboardContent = ({ counts, loading }) => {
  const { users, troopers, orders } = counts;
  return (
    <>
      <DashboardStyle>
        <div className="card">
          <div className="icon red">
            <FaRegUser />
          </div>
          <div>
            <h2>Troupers</h2>
            <div>{troopers}</div>
          </div>
        </div>
        <div className="card">
          <div className="icon blue">
            <FaUsers />
          </div>
          <div>
            <h2>Users</h2>
            <div>{users}</div>
          </div>
        </div>
        <div className="card">
          <div className="icon yellow">
            <FiBox />
          </div>
          <div>
            <h2>Orders</h2>
            <div>{orders}</div>
          </div>
        </div>
      </DashboardStyle>
    </>
  );
};

export default DashboardContent;
