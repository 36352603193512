import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchBar = () => {
  return (
    <div className="search">
      <Input
        placeholder="Search..."
        suffix={<SearchOutlined />}
        style={{ width: 200, borderRadius: 5, padding: 7 }}
      />
    </div>
  );
};

export default SearchBar;
