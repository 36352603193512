import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUsers } from '../../state/actions/users';
import { MainViewStyle } from '../../components/styles/MainView';
import SearchBar from '../../components/Admin/SearchBar';
import Layout from './Layout';
import Users from '../../components/Admin/Users';

const UserList = ({ getUsers, loading, users }) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  return (
    <Layout>
      <MainViewStyle>
        <h1>User List</h1>
        <SearchBar />
        <Users loading={loading} users={users} />
      </MainViewStyle>
    </Layout>
  );
};

const mapStateToProps = ({ userReducer }) => ({
  loading: userReducer.loading,
  users: userReducer.users,
  error: userReducer.error,
});

export default connect(mapStateToProps, {
  getUsers,
})(UserList);
