import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCounts } from '../../state/actions/users';
import { MainViewStyle } from '../../components/styles/MainView';
import Layout from './Layout';
import DashboardContent from '../../components/Admin/DashboardContent';

const Dashboard = ({ getCounts, counts, loading }) => {
  useEffect(() => {
    getCounts();
  }, [getCounts]);
  return (
    <Layout>
      <MainViewStyle>
        <h1>Overview</h1>
        <DashboardContent counts={counts} loading={loading} />
      </MainViewStyle>
    </Layout>
  );
};

const mapStateToProps = ({ userReducer }) => ({
  loading: userReducer.loading,
  counts: userReducer.counts,
  error: userReducer.error,
});

export default connect(mapStateToProps, {
  getCounts,
})(Dashboard);
