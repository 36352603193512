import { apiURL } from '../../config';
import { axiosWithAuth } from '../../utils/axiosWithAuth';

export const USERS_LOADING = 'USERS_LOADING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_COUNTS_SUCCESS = 'GET_COUNTS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_COUNTS_FAILURE = 'GET_COUNTS_FAILURE';

export const getUsers = (page = 0) => async (dispatch) => {
  dispatch({ type: USERS_LOADING });
  try {
    const res = await axiosWithAuth().get(`${apiURL}/admin/users?page=${page}`);
    dispatch({ type: GET_USERS_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({
      type: GET_USERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};

export const getCounts = () => async (dispatch) => {
  dispatch({ type: USERS_LOADING });
  try {
    const res = await axiosWithAuth().get(`${apiURL}/admin/counts`);
    dispatch({ type: GET_COUNTS_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({
      type: GET_COUNTS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};
