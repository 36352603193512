import React from 'react';
import Footer from '../../components/Landing/Footer';
import { HomeStyles } from '../styles/Home';
import google from '../../assets/google-play-badge.png';
import apple from '../../assets/apple.png';
import app from '../../assets/app.svg';
import HomeNav from '../../components/Landing/HomeNav';

const Terms = () => {
  return (
    <HomeStyles>
      <HomeNav />
      <h2>Terms and conditions</h2>
      <div className="underline"></div>
      <section className="download-app">
        <div className="order">
          <div className="vertical">Ready to order?</div>
          <div className="app">
            <img src={app} alt="screenshot of app to download" />
            <h2>Download ELA app today</h2>
            <div className="store">
              <div>
                <img src={apple} alt="Apple download badge" />
              </div>
              <div>
                <img src={google} alt="Google download badge" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </HomeStyles>
  );
};

export default Terms;
