import { apiURL } from '../../config';
import { axiosWithAuth } from '../../utils/axiosWithAuth';

export const ORDERS_LOADING = 'ORDERS_LOADING';
export const GET_PENDING_ORDERS_SUCCESS = 'GET_PENDING_ORDERS_SUCCESS';
export const GET_DELIVERED_ORDERS_SUCCESS = 'GET_DELIVERED_ORDERS_SUCCESS';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_PENDING_ORDERS_FAILURE = 'GET_PENDING_ORDERS_FAILURE';
export const GET_DELIVERED_ORDERS_FAILURE = 'GET_DELIVERED_ORDERS_FAILURE';
export const GET_ORDER_DETAIL_FAILURE = 'GET_ORDER_DETAIL_FAILURE';

export const getPendingOrders = (page = 0) => async (dispatch) => {
  dispatch({ type: ORDERS_LOADING });
  try {
    const res = await axiosWithAuth().get(
      `${apiURL}/admin/pending-orders?page=${page}`
    );
    dispatch({ type: GET_PENDING_ORDERS_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({
      type: GET_PENDING_ORDERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};

export const getDeliveredOrders = (page = 0) => async (dispatch) => {
  dispatch({ type: ORDERS_LOADING });
  try {
    const res = await axiosWithAuth().get(
      `${apiURL}/admin/delivered-orders?page=${page}`
    );
    dispatch({ type: GET_DELIVERED_ORDERS_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({
      type: GET_DELIVERED_ORDERS_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};

export const getOrderDetails = (id) => async (dispatch) => {
  dispatch({ type: ORDERS_LOADING });
  try {
    const res = await axiosWithAuth().get(`${apiURL}/admin/order-detail?orderId=${id}`);
    dispatch({ type: GET_ORDER_DETAIL_SUCCESS, payload: res.data.data.orderDetail });
  } catch (error) {
    dispatch({
      type: GET_ORDER_DETAIL_FAILURE,
      payload: error.response?.data.message,
    });
    return error;
  }
};
