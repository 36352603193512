import React from 'react';
import { connect } from 'react-redux';
import { Table, Space, Avatar } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getUsers } from '../../state/actions/users';

const { Column } = Table;

const Users = ({ loading, users, getUsers }) => {
  const { users: data } = users;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
      <Table
        dataSource={data}
        rowKey="id"
        pagination={{
          position: 'bottomLeft',
          defaultPageSize: 10,
          total: users ? users.totalItems : '',
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          onChange: (page) => {
            let currentNo = page - 1;
            getUsers(currentNo);
          },
        }}
        rowClassName="row"
        loading={{ spinning: loading, indicator: antIcon }}
      >
        <Column
          title="Name"
          render={(item) => (
            <Space size="large" align="baseline">
              <Avatar size="large" src={item.imageUrl} alt={item.firstName} />
              <h3>
                {item.firstName} {item.surname}
              </h3>
            </Space>
          )}
        />
        <Column
          title="Email Address"
          render={(item, record) => <>{item.email}</>}
        />
        <Column title="Phone" render={(item, record) => <>{item.phone}</>} />
        <Column
          title="Account Type"
          render={(item) => (
            <>{item.userType === 1 ? 'Indivdual' : 'Business'}</>
          )}
        />
      </Table>
    </>
  );
};

export default connect(null, { getUsers })(Users);
