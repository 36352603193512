import React from 'react';
import { connect } from 'react-redux';
import { Table, Space, Avatar, Tag } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { RiArrowLeftRightLine } from 'react-icons/ri';

import { Link } from 'react-router-dom';

const { Column } = Table;

const Pending = ({ loading, pending, getPendingOrders }) => {
  const { orders: data } = pending;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <Table
      size='middle'
        dataSource={data}
        rowKey="id"
        pagination={{
          position: 'bottomLeft',
          defaultPageSize: 10,
          total: pending ? pending.totalItems : '',
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          onChange: (page, size) => {
            let currentNo = page - 1;
            getPendingOrders(currentNo);
          },
        }}
        rowClassName="row"
        loading={{ spinning: loading, indicator: antIcon }}
        onChange={(page, size) => {
          let currentNo = page - 1;
          getPendingOrders(currentNo);
        }}
      >
        <Column title="Order NO." dataIndex="id" responsive={['md', 'lg']} />

        <Column
          title="Status"
          dataIndex="status"
          responsive={['md']}
          render={(status) =>
            status === 3 ? (
              <Tag color="pink">Awaiting Pickup</Tag>
            ) : status === 4 ? (
              <Tag color="yellow">In Transit</Tag>
            ) : status === 5 ? (
              <Tag color="blue">Picked up</Tag>
            ) : status === 7 ? (
              <Tag color="green">Delivered</Tag>
            ) : (
              ''
            )
          }
        />
        <Column
          title="Operator"
          responsive={['md', 'lg']}
          render={(item) => {
            return item.trooper ? (
              <Space size="large" align="baseline">
                <Avatar
                  size="large"
                  src={item.trooper.User.imageUrl}
                  alt={item.trooper.User.firstName}
                />
                <h3>
                  {item.trooper.User.firstName} {item.trooper.User.surname}
                </h3>
              </Space>
            ) : (
              <div style={{ color: '#bb2124' }}>
                <ExclamationCircleOutlined />
                <span> Not Yet Assigned</span>
              </div>
            );
          }}
        />

        <Column
          title="Address"
          responsive={['md', 'lg']}
          render={(item) => (
            <div className="order-actions">
              <div className="address" style={{ fontSize: 14 }}>
                <span>{item.pickupAddress}</span>
                <RiArrowLeftRightLine />
                <span>{item.deliveryAddress}</span>
              </div>
              <div className="action">
                <Link to={`/order/${item.id}`}>
                  <button>View Details</button>
                </Link>
              </div>
            </div>
          )}
        />
      </Table>
    </>
  );
};

export default connect(null, {})(Pending);
