import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import Layout from './Layout';
import lock from '../../assets/lock.svg';
import { ResetStyle } from '../styles/Form';
import { generateForgotPasswordCode } from '../../state/actions/admin';

const { Item } = Form;

const FindEmail = ({ generateForgotPasswordCode, loading }) => {
  let history = useHistory();

  const onSubmit = async (values) => {
    try {
      const res = await generateForgotPasswordCode(values);
      if (res.status !== 200) {
        notification['error']({
          message: 'Error',
          description: res.message,
          duration: 5,
        });
      } else {
        notification['success']({
          message: 'Success',
          description: res.data.message,
          duration: 5,
        });

        history.push('/password-verify-email');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <ResetStyle>
        <h2>Forget Password</h2>
        <img src={lock} alt="padlock" />
        <p>Enter your email address to reset your password</p>

        <Form
          layout="vertical"
          className="register"
          initialValues={{ email: '' }}
          onFinish={onSubmit}
          scrollToFirstError
        >
          <Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail.',
              },
              {
                required: true,
                message: 'Please input your E-mail.',
              },
            ]}
          >
            <Input />
          </Item>
          <Item>
            <Button htmlType="submit" block loading={loading}>
              Next
            </Button>
            <div className="link">
              Already have an Account? <Link to="/login">Log In</Link>
            </div>
          </Item>
        </Form>
      </ResetStyle>
    </Layout>
  );
};

const mapStateToProps = ({ adminReducer }) => ({
  loading: adminReducer.loading,
  success: adminReducer.success,
  error: adminReducer.error,
});

export default connect(mapStateToProps, {
  generateForgotPasswordCode,
})(FindEmail);
