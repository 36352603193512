import React from 'react';
import { Descriptions } from 'antd';

const Guarantors = ({ guarantor, idx }) => {
  return (
    <Descriptions
      title={`Guarantor ${idx + 1} info`}
      size="default"
      style={{ marginBottom: 20, fontSize: 16 }}
    >
      <Descriptions.Item label="Title">{guarantor.title}</Descriptions.Item>
      <Descriptions.Item label="First Name">
        {guarantor.firstName}
      </Descriptions.Item>
      <Descriptions.Item label="Surame">{guarantor.surname}</Descriptions.Item>
      <Descriptions.Item label="Email Address">
        {guarantor.email}
      </Descriptions.Item>
      <Descriptions.Item label="Phone Number">
        {guarantor.phone}
      </Descriptions.Item>
      <Descriptions.Item label="Home Address">
        {guarantor.homeAddress}
      </Descriptions.Item>
      <Descriptions.Item label="Profession">
        {guarantor.profession}
      </Descriptions.Item>
      <Descriptions.Item label="Office Address">
        {guarantor.officeAddress}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default Guarantors;
